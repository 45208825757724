import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Foot  from '../components/Foot'
import Benefits from  '../components/Benefits'
/*
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
*/
import bull from '../assets/images/istockphoto-1158762452-1024x1024.jpg'
import ads from '../assets/images/adver.png'
import mail from '../assets/images/mail.png'
import call from '../assets/images/call.png'
import ppc from '../assets/images/ppc.png'
import seo from '../assets/images/seo.png'
import social from '../assets/images/social.png'
import ResponsiveIframe from '../components/ResponsiveIframe'

const Introduction = props => (
  <Layout>
  <Helmet
  htmlAttributes={{ lang: 'en' }}
  title="Introduction - Bulls i Marketing"
  meta={[
    { name: 'description', content: '' },
    { name: 'keywords', content: ' ' },
  ]}
></Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
      <article>
        <header className="major">
          <h2>Introduction</h2>
          <p>
          <b>Bulls i Marketing</b> has been giving custom marketing solutions, Internet marketing strategies, and personalized search engine marketing strategies for more than 15 years, and we value our client connections. 
          <b>
          Bulls i Marketing's</b> licensed "search engineers," have come out on top in growing profoundly effective, exclusive search engine optimization, and marketing techniques that have put several driving enterprises in the top rankings of the world's significant search properties.
          </p>
        </header>

        <section>
          <p>
            We customize all of our campaigns according to our customers’ needs and their particular industry. We do not outsource any work to any overseas companies and perform everything in-house. This keeps us strictly adhered to all the best practices approved by all the industry and quality control guidelines. Our full in-house marketing services include web design,  print design, brand development, radio and television script development and production services, website optimization, content writing and optimization, code optimization, image and mobile optimization, SEM services, blog and website design and development, competitive/industry strategic analysis, image and video syndication, press releases, press releases, social media marketing, social bookmarking, directory submission, Google product feeds, local search inclusion, banner advertisement, detailed tracking and reporting analytics, pay-per-click management, optimization management, paid search marketing, and extensive research and development. 
          </p>
          <p>
             Bulls i Marketing Company’s on-site optimization tactic is the most effective, fully developed, and respected in the entire industry. We use “white hat” methodology utilizing a strategic approach that is incorporated with all aspects of what a search engine wants from your site has but not limited to the following: site structure, site size, site architecture, site design, page size, hosting quality, URL structure, site navigation, link design, content, internal linking, keyword density, source code optimization, tag structure, site accessibility tags, XML sitemaps, image optimization, flash optimization, blog optimization, videos optimization, call-to-action implementation and optimization, and conversion optimization. 
          </p>
          <p>
          We have more than 30 in-house experts that manage all of the clients’ campaigns. Every client can contact the Senior Project Manager dedicated to their project at any time. We also communicate weekly to let our clients know what’s happening at all times with their accounts. A typical comprehensive campaign has at least four to eight experts working on it including Artistic Director, Project Manager, Agency Services Director, SEO Specialists, SEM Managers, Link Specialists, Social Media Experts, Copywriters, Strategists/Analysts, Analytics Specialists, and Web Developers. 
          </p>
            <ResponsiveIframe url="https://fast.wistia.net/embed/iframe/9cos4ub1eg"></ResponsiveIframe>
        <hr />
     
        </section>

          <section>
            <h2><i>Our company is divided into distinct sections and brands for the service of our customers.</i> </h2>
          </section>

          <section>
          <span className="image left">
                <img src={ads} alt="" />
         </span>
            <h3>Advertising Agency</h3>
            <p>
            As an Advertising Agency, we have only one mission: to ensure client satisfaction. A happy client is a busy customer and nothing else matters. It takes a lot of dedication, energy leadership, and confidence to create productive ad campaigns with measurable results. We just expect this from our creative analytical and mastermind professionals, and you expect nothing less from our advertising agency. 
            </p>
            <p>
                We are not a common advertising agency; we were born digital. The internet is our backyard knowing how it can shape up the lives of consumers along with businesses. Our advertising agency is working day-in-day-out bringing our clients into the 21st century while advertising strategies are changing along with the television, radio, internet evolution as print is far from dead. We critically examine the trends. We know what it takes to develop extraordinary advertising campaigns for people who are consuming their favorite content on television, music, and publications online and print to attract the customers you need. We are an Advertising Agency that’s all about creating a mixed media marketing experience by bringing new and old media together. 
            </p>
            <br />
            <hr />
        </section>

        <section>
        <span className="image right">
            <img src={seo} alt="" />
        </span>
            <h3>SEO Company</h3>
            <p>
                SEO Company is the division that performs search engine optimization of our company. SEO Company helps clients from numerous industries and from all over the world achieve improved organic search results. 
            </p>
            <p>
                SEO Company is known worldwide for our leading services and a super-expert group of talented SEO professionals. We are just as excited as our clients when we achieve their search engine ranking targets and take pride in what we do for them. Whether you are a Fortune 500 client or a small to medium-sized business, our team gets HYPED about your profit. 
            </p>

            <br />
            <hr />
        </section>

        <section>
        <span className="image left">
                <img src={ppc} alt="" />
        </span>
            <h3>PPC Management Company</h3>
            <p>
                We started helping our clients in the internet marketing service since 2001 when the PPC management dawned. With the acclaimed package PPC Pro, we started as a software company, added PPC services, and eventually started to provide nothing but full-time PPC management services to our clients when Bulls i Marketing acquired PPC Management in 2007. 
            </p>
            <p>
                We kept on improving our proprietary software and still use it in-house to help cherish our clients the best results for paid search advertisements. We mainly focus on the ROI and measurement of our client’s campaigns. We don’t only drive traffic, but we drive the right traffic at the right moment utilizing the right experience. We offer the exact information clients want to know and get them to click on our ads, there they click, here we GET THE RESULTS.
            </p>

            <br />
            <hr />
        </section>

        <section>
        <span className="image right">
            <img src={social} alt="" />
        </span>
            <h3>Reputation Management Company</h3>
            <p>
                Reputation Management Services are usually deployed as a subset of search engine optimization, but Bulls i Marrketing takes it to another level. We understand that the improvement of negative search results should be our first and foremost priority by highlighting, creating, and exposing your audience to positive content about your organization, but we also find out the root issues and help determine what caused your company to face these negative publicity problems.
            </p>
            <p>
                If a client doesn’t want to tackle the root issues, or simply can’t do this, there is little need for our service, as we just work with clients who are willing to not only heal their current reputation but also want a continual improvement. We encourage our clients to integrate customer support portals that open up outlets and communication points for clients’ concerns rather than feel the need to post negative information about your services. While some others consider the “reverse SEO” strategy to improve their reputation due to negative search results by promoting the most favorable online content at the “top” and negative “bottom”.
            </p>
            <br />
            <hr />
        </section>

        <section>
        <span className="image left">
            <img src={call} alt="" />
        </span>
            <h3>CallTraxPlus</h3>
            <p>
                For years and years, business owners believed in the statement, “I know half of my advertising campaigns are wasted – I don’t know which half”. The CallTrax Plus advertising campaign tracking solution allows us to comprehensively measure and track advertising through an effective combination of phone call tracking and keyword tracking software. This appears to be the best system for any kind of advertising, both traditional and online, the Web 2,0 features the ability “get your advertising verified”TM.
            </p>
            <p>
                As traditional and online advertising results in responses, sales, and leads via phone calls. Our tracking tool will help you enable ad monitoring that differentiates between successful and unsuccessful campaigns allowing you to choose where to spend your budget to get the best results.    
            </p>
            <p>
                No company has the ad verification feature in their ad tracking system or ad tracking software, but we offer it with the power of CallTrax Plus. 
            </p>
            <br />
            <hr />
        </section>

        <section>
        <span className="image right">
            <img src={mail} alt="" />
        </span>
            <h3>MailTraxPlus</h3>
            <p>
            You need an email marketing service whether you run a business or email marketing service Bulls i Marketing takes care of the complicated stuff helping you focus on your day-to-day tasks. MailTrax Plus lets you design top-notch email campaigns, integrate with web services you use, share them on social media networks, manage subscribers, and track campaign results. 
            </p>
            <p>
            You’ll love the matching and mixing of MailTrax Plus’s features for templates, functions, and integrations to suit your demands. MailTrax Plus offers you exceptional reporting and Google Analytics integration. Google Analytics allows you to check your instant ROI results as your campaigns could be generating great revenue. 
            </p>
            <br />
            <hr />
        </section>

        <section>
        <span className="image left">
            <img src={bull} alt="" />
        </span>
            <h3>Bulls i Marketing! Company</h3>
            <p>
            The divisions responsible for all varieties of “support services” are under our corporate parent brand named as Bulls i Marketing Company. Our support services include search engine-friendly website design, hosting, domain acquisition, email campaign management, graphic designing, (Stop right here. Are you really reading this long introduction? You’ll be surprised when you sign-up with us. Alright, continue now.) analytics installation services, web 2.0 community building, general content development, and much, much more.
            </p>

        </section>
        <br /><br />
        <hr />


        </article>
       </div>
       <div className="container">    
            <Benefits />
        </div>
    </div>
    
    <Foot title="WE’RE A COMPLETE SOLUTION FOR YOUR INTERNET MARKETING"
    description="  Our company tends to provide as easy and convenient as possible to solutions to get their needs fulfilled in a “single source” environment. Bulls i Marketing Company is our primary business organization for Web projects partnering with all types of other organizations for collaboration." />
  </Layout>
)

export default Introduction
